@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

/* html * {
  font-family: 'Archivo Black', sans-serif;
} */

html, body, .wrapper {
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
}